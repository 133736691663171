// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { SvgIconProps } from '@mui/material';

import AccessibleSvgIcon from './helpers/AccessibleSvgIcon';
import CoffeeBreak from './source/coffee-break.svg?react';

const CoffeeBreakIcon = (props: SvgIconProps) => <AccessibleSvgIcon {...props} component={CoffeeBreak} />;

export default CoffeeBreakIcon;
