// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { Container, Stack, ThemeProvider, Typography, styled, useTheme } from '@mui/material';
import { RoomId } from '@opentalk/rest-api-rtk-query';
import { LocalAudioTrack } from 'livekit-client';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetRoomEventInfoQuery } from '../../api/rest';
import { BackIcon, LogoIcon } from '../../assets/icons';
import arrowImage from '../../assets/images/arrow-illustration.png';
import { createOpenTalkTheme } from '../../assets/themes/opentalk';
import { CircularIconButton, IconButton as MuiIconButton } from '../../commonComponents';
import { CircularIconButtonStyles } from '../../commonComponents/IconButtons/CircularIconButton';
import { useAppSelector } from '../../hooks';
import { useInviteCode } from '../../hooks/useInviteCode';
import { useIsMobile } from '../../hooks/useMediaQuery';
import useNavigateToHome from '../../hooks/useNavigateToHome';
import { selectFeatures } from '../../store/slices/configSlice';
import { selectVideoEnabled, selectAudioEnabled } from '../../store/slices/mediaSlice';
import { BreakoutRoomId } from '../../types';
import MyMeetingMenu from '../MeetingHeader/fragments/MyMeetingMenu';
import SpeedTestDialog from '../SpeedTestDialog';
import EchoPlayBack from './fragments/EchoPlayback';
import ToolbarContainer from './fragments/ToolbarContainer';
import VideoElement from './fragments/VideoElement';

const InnerContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: 0,
  width: '100%',
  maxWidth: '1200px',

  //The blur should be part of the theme and handled globally
  backdropFilter: 'blur(100px)',
  WebkitBackdropFilter: 'blur(100px)',
  background: `rgba(0, 22, 35, 0.5) url(${arrowImage}) no-repeat 77% 67%`,
  backgroundSize: '10rem',
  '& .MuiButtonBase-root.Mui-focusVisible': {
    outline: theme.palette.focus.contrastOutline,
  },
}));

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 0),

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
}));

const UtilitiesContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
  '& div:has(#my-meeting-menu-button)': {
    backgroundColor: 'transparent',
    alignSelf: 'start',
  },
  '& #my-meeting-menu-button': {
    ...CircularIconButtonStyles(theme),
    '& .MuiSvgIcon-root': {
      fontSize: '1em',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover, &:focus, &[aria-expanded="true"]': {
      background: theme.palette.secondary.lightest,
      color: theme.palette.text.primary,
    },
  },
}));

const MOBILE_BACK_BUTTON_Z_INDEX = 1;

const MobileBackButton = styled(CircularIconButton)(({ theme }) => ({
  position: 'absolute',
  zIndex: MOBILE_BACK_BUTTON_Z_INDEX,
  bottom: theme.spacing(2),
  left: theme.spacing(2),
}));

const MonitorContainer = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  aspectRatio: '16/9',

  '& h1, p': {
    color: theme.palette.secondary.contrastText,
  },
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  '& > .MuiSvgIcon-root': {
    height: '2rem',
    width: 'auto',
  },
}));

interface SelftestProps {
  children: ReactNode;
  actionButton?: ReactNode;
  waitingRoom?: boolean;
}

const SelfTest = ({ children, actionButton, waitingRoom }: SelftestProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigateToHome = useNavigateToHome();
  const inviteCode = useInviteCode();
  const isMobile = useIsMobile();
  const [localAudioTrack, setLocalAudioTrack] = useState<LocalAudioTrack | undefined>();
  const [mounted, setMounted] = useState(false);
  const { joinWithoutMedia } = useAppSelector(selectFeatures);
  const videoEnabled = useAppSelector(selectVideoEnabled);
  const audioEnabled = useAppSelector(selectAudioEnabled);

  useEffect(() => {
    !mounted && setMounted(true);
  });

  const { roomId } = useParams<'roomId' | 'breakoutRoomId'>() as {
    roomId: RoomId;
    breakoutRoomId?: BreakoutRoomId;
  };
  const { data: roomData } = useGetRoomEventInfoQuery({ id: roomId, inviteCode: inviteCode }, { skip: !roomId });

  return (
    <Container>
      <InnerContainer>
        <Header>
          <IconButton onClick={navigateToHome} aria-label={t('conference-go-home')}>
            <LogoIcon />
          </IconButton>
          <UtilitiesContainer>
            <SpeedTestDialog />
            {!isMobile && (
              <ThemeProvider theme={createOpenTalkTheme('dark')}>
                <MyMeetingMenu />
              </ThemeProvider>
            )}
          </UtilitiesContainer>
        </Header>

        <MonitorContainer>
          {mounted && videoEnabled ? (
            <VideoElement />
          ) : (
            <>
              {roomData?.title && (
                <Typography
                  variant="h2"
                  textAlign="center"
                  color={theme.palette.common.white}
                  marginBottom={theme.spacing(5)}
                  component="h1"
                >
                  {t('joinform-room-title', { title: roomData?.title })}
                </Typography>
              )}
              <Typography
                variant="h1"
                textAlign="center"
                fontSize="2.9rem"
                lineHeight="2.9rem"
                mb={2}
                component="h2"
                color={theme.palette.common.white}
                lang="en"
              >
                {t('selftest-header')}
              </Typography>
              <Typography textAlign="center" fontSize="1.37rem" padding="0 0.5rem">
                {joinWithoutMedia ? t('selftest-body-do-test') : t('selftest-body')}
              </Typography>
            </>
          )}
          {mounted && audioEnabled && (
            <EchoPlayBack localAudioTrack={localAudioTrack} setLocalAudioTrack={setLocalAudioTrack} />
          )}
        </MonitorContainer>

        <ToolbarContainer localAudioTrack={localAudioTrack} actionButton={actionButton} waitingRoom={waitingRoom}>
          {children}
        </ToolbarContainer>

        {isMobile && (
          <MobileBackButton aria-label={t('global-back')} onClick={navigateToHome}>
            <BackIcon />
          </MobileBackButton>
        )}
      </InnerContainer>
    </Container>
  );
};

export default SelfTest;
