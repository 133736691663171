// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { SvgIconProps } from '@mui/material';

import AccessibleSvgIcon from './helpers/AccessibleSvgIcon';
import Forward from './source/forward.svg?react';

const ForwardIcon = (props: SvgIconProps) => <AccessibleSvgIcon {...props} component={Forward} />;

export default ForwardIcon;
