// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import React from 'react';

export const MenuTabs = React.lazy(() => import('../../components/MenuTabs'));
export const BreakoutRoomTab = React.lazy(() => import('../../components/BreakoutRoomTab'));
export const LegalVoteTab = React.lazy(() => import('../../components/LegalVoteTab'));
export const TalkingStickTabPanel = React.lazy(
  () => import('../../components/TalkingStickTabPanel/TalkingStickTabPanel')
);
export const PollTab = React.lazy(() => import('../../components/PollTab'));
export const MuteParticipantsTab = React.lazy(() => import('../../components/MuteParticipantsTab'));
export const MeetingNotesTab = React.lazy(() => import('../../components/MeetingNotesTab'));
export const ResetHandraisesTab = React.lazy(() => import('../../components/ResetHandraisesTab'));
export const TimerTab = React.lazy(() => import('../../components/TimerTab'));
export const WhiteboardTab = React.lazy(() => import('../../components/WhiteboardTab'));
