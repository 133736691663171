// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2

export const MY_MEETING_MENU_BUTTON_ID = 'my-meeting-menu-button';
export const CHAT_INPUT_ID = 'chat-input';

export enum ToolbarButtonIds {
  Handraise = 'toolbar-handraise',
  ShareScreen = 'toolbar-share-screen',
  Audio = 'toolbar-audio',
  Video = 'toolbar-video',
  More = 'toolbar-more',
  EndCall = 'toolbar-endcall',
}

export const LIVEKIT_SCREEN_SHARE_PERMISSION_NUMBER = 3;
export const LIVEKIT_AUDIO_PERMISSION_NUMBER = 2;

export const MAX_GRID_TILES_DESKTOP = 9;
export const MAX_GRID_TILES_MOBILE = 9;
