// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { SvgIconProps } from '@mui/material';

import AccessibleSvgIcon from './helpers/AccessibleSvgIcon';
import Moderator from './source/moderator.svg?react';

const ModeratorIcon = (props: SvgIconProps) => <AccessibleSvgIcon {...props} component={Moderator} />;

export default ModeratorIcon;
