// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2

export enum FieldKeys {
  InviteLink = 'invite-link',
  RoomLink = 'room-link',
  SipLink = 'sip-link',
  GuestLink = 'guest-link',
  RoomPassword = 'room-password',
  SharedFolderLink = 'shared-folder-link',
  SharedFolderPassword = 'shared-folder-password',
  LivestreamLink = 'livestream-link',
}
