// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { SvgIconProps } from '@mui/material';

import AccessibleSvgIcon from './helpers/AccessibleSvgIcon';
import DashboardLegal from './source/dashboard-legal.svg?react';

const DashboardLegalIcon = (props: SvgIconProps) => <AccessibleSvgIcon {...props} component={DashboardLegal} />;

export default DashboardLegalIcon;
